import React, { useState } from 'react';
import { BrowserRouter, Link, Routes, Route } from 'react-router-dom';
// import { HomePage, Gallery, Trivia, Rules } from '../pages';
import HomePage from './HomePage';
import Trivia from './Trivia';
import Rules from './Rules';

import "../components/shared/domainDefault.scss"
import "../components/shared/masterNavbar.scss"

const Protection: React.FC = () => {
    const [password, setPassword] = useState('');
    const [showContent, setShowContent] = useState(false);

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (password === 'moN@c0$20') {
            setShowContent(true);
        } else {
            setPassword('');
            alert('Wrong password, try again');
        }
    };

    return (
        <div>
            {!showContent ? (

                <form className='protectionForm' onSubmit={handleSubmit}>
                    <label>
                        <h4>Password: </h4>
                        <input type="password" value={password} onChange={handlePasswordChange} />
                    </label>
                    <button type="submit">Enter</button>
                </form>
            ) : (
                <div>

                    <BrowserRouter>
                        <nav id="MasterNavbar" >
                            <ul>
                                <li><Link to="Home">Home</Link></li>
                                <li><Link to="Trivia">Trivia</Link></li>
                                <li><Link to="Rules">Rules</Link></li>
                            </ul>
                        </nav>
                        <main>
                            <Routes>
                                <Route path='/' element={<HomePage />}></Route>
                                <Route path='Home' element={<HomePage />}></Route>
                                <Route path='Trivia' element={<Trivia />}></Route>
                                <Route path='Rules' element={<Rules />}></Route>
                            </Routes>
                        </main>
                    </BrowserRouter>

                </div>
            )}
        </div>
    );
};

export default Protection;
