import "../components/shared/domainDefault.scss";
import "./leaderboard.scss";

const Leaderboard = () => {
    return (
        <>
        <h3>Alltime leaderboard:</h3>
            <table className="table_allTime">
                <tr className="tableHeader">
                    <th>Position</th>
                    <th>Team name</th>
                    <th>ABBR</th>
                    <th>Points</th>
                    <th>Year</th>
                </tr>
                <tr>
                    <td id="position">1</td>
                    <td id="teamName">Mojos Makskiner</td>
                    <td id="abbrOwner">JM</td>
                    <td id="score">220</td>
                    <td id="year">2022</td>
                </tr>
                <tr>
                    <td id="position">2</td>
                    <td id="teamName">Mazespin Fan Club</td>
                    <td id="abbrOwner">ENL</td>
                    <td id="score">213</td>
                    <td id="year">2022</td>
                </tr>
                <tr>
                    <td id="position">3</td>
                    <td id="teamName">Team Heuer</td>
                    <td id="abbrOwner">OA</td>
                    <td id="score">174</td>
                    <td id="year">2022</td>
                </tr>
                <tr>
                    <td id="position">4</td>
                    <td id="teamName">Team 1.</td>
                    <td id="abbrOwner">SW</td>
                    <td id="score">164</td>
                    <td id="year">2022</td>
                </tr>
            </table>

        </>
    )
}
export default Leaderboard;