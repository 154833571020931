import "../src/components/shared/domainDefault.scss";
import MasterNavbar from "./components/shared/MasterNavbar";
import HomePage from "./pages/HomePage";
import Protection from "./pages/Protection";


function App() {
  return (
    <div>
      {/* <header> */}
        {/* <MasterNavbar/> */}
      {/* </header> */}

      <section>
        <Protection/>
      </section>
      
      {/* <main> */}
      {/*  */} 
      {/* <HomePage/> */}
      {/* </main> */}
      
      <footer>
        <p>
          <strong>Version:</strong> 1.0.1
          <br />
          <strong>Support:</strong> support@elvagp.net
        </p>
      </footer>
    </div>
  );
}

export default App;