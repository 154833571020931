import "../components/shared/domainDefault.scss";
import "./catering.scss";
import catering2022 from "../components/images/example_Images/2022-catering.jpg"

const Catering = () => {
    return (
        <>
            <section className="cateringTable">
                <img id="cateringImage" src={catering2022} alt="img_catering" />
            </section>
        </>
    )
}

export default Catering;