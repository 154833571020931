// import "../src/components/shared/domainDefault.scss";
import "../components/shared/domainDefault.scss";
import "./homePage.scss";
import "./Leaderboard";
import "./Catering"
import "./SubscribeForm";
import Leaderboard from "./Leaderboard";
import Catering from "./Catering";
import SubscribeForm from "./SubscribeForm";

// import "./subscribeForm.scss";


const HomePage = () => {
    return (
        <div>

            <section>
                <Leaderboard />
            </section>

            <section>
                <Catering />
            </section>

            <section>
                <SubscribeForm />
            </section>

        </div>
    )
}

export default HomePage;


// export{}