import "../components/shared/domainDefault.scss";
import "./subscribeForm.scss";

const SubscribeForm = () => {
    return (
        <>
            <h3>Hypp på å delta i år?</h3>
            <p>Send mail til support for informasjon om hvordan.</p>
        </>
    )
}
export default SubscribeForm;











// import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';

// export const ContactUs = () => {
//   const form = useRef();

//   const sendEmail = (e: { preventDefault: () => void; }) => {
//     e.preventDefault();

//     emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
//       .then((result: { text: any; }) => {
//           console.log(result.text);
//       }, (error: { text: any; }) => {
//           console.log(error.text);
//       });
//   };

//   return (
//     <form ref={form} onSubmit={sendEmail}>
//       <label>Name</label>
//       <input type="text" name="user_name" />
//       <label>Email</label>
//       <input type="email" name="user_email" />
//       <label>Message</label>
//       <textarea name="message" />
//       <input type="submit" value="Send" />
//     </form>
//   );
// };

// export {}