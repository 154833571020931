import "../components/shared/domainDefault.scss";
// import "./rules.scss";
import "./trivia.scss";
import trackMap from '../components/images/example_Images/monaco_track-map.png'



const Trivia = () => {
    return (
        <>

<section className="contentDisclaimer">
    <h3>ikke alle disse factsene kan quotes som fakta, derfor kanskje best å la være. Innhold opdateres ytterst skjeldent...</h3>
</section>

            <section className="contentTrivia">
                <img src={trackMap} alt="track-map" />
            </section>  

            <section className="contentTrivia">
                <p>Raskeste runde er holdt av Lewis Hamilton, 1:12.09 (2021).</p>
                <p>Første Monaco GP var i 1950.</p>
                <p>Er den korteste banen i kalenderen, 3.340km.</p>
                <p>Kun Alberto Ascari og Paul Hawkins har klart å kjøre ut i vannet.</p>
                <p>Løpet med flest runder i kalenderen, 78.</p>
                <p>Fører: Ayrton Senna har vunnet flest ganger, 6 seiere.</p>
                <p>Konstruktør: McLaren er har vunnet flest, husker ikke hvor mange.</p>
            </section>



        </>
    )
}
export default Trivia;