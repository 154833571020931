import "../components/shared/domainDefault.scss";
import "./rules.scss";

const Rules = () => {
    return (
        
        <div className="ruleBook">
            <section className="tableOfContent">
                <li>
                    <ul>§1 Krav for Deltakelse og alminnelig innpassering
                        <ul>§1.1 Kleskode</ul>
                        <ul>§1.2 Fantasy</ul>
                        <ul>§1.3 Konkurransen</ul>
                        <ul>§1.4 Kleskode</ul>
                        <ul>§1.5 Tie-breaker</ul>
                    </ul>
                </li>
                <li>
                    <ul>§2 House Rules
                        <ul>§2.1 Pit-Stop</ul>
                        <ul>§2.2 Racing</ul>
                        <ul>§2.3 Radio</ul>
                    </ul>
                </li>
            </section>

            <section className="chapterOne">

                <div className="chapter">
                    <h2 className="chapterHead">
                        Krav for Deltakelse og alminnelig innpassering.
                    </h2>
                    <p className="chapterAbout">Dog få, -er det enkelte krav som må imøtekommes av
                        samtlige deltakere for å få innpass til arrangementet.
                    </p>
                    <br />
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §1.1 Kleskode
                    </h4>
                    <p className="paragraphAbout">
                        Det er opprettet en kleskode for deltakere til arrangementet. Minstekravet er ikke av en streng art, men det er allikevel
                        enkelte minimumskrav som stilles for å opprettholde et visst nivå av klasse.
                        Alle deltakere skal ved innpassering være ikledd minimum en krage. Hvordan gjestene løser dette er opp til den enkelte.
                        Ved unnlatelse av å imøtekomme overnevnt krav vil gjesten stå i fare for å bli nektet innpass, såfremt legeerklæring eller
                        rimelig begrunnelse ikke foreligger.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §1.2 Fantasy
                    </h4>
                    <p className="paragraphAbout">
                        Hovedattraksjonen, -og formålet for arrangementet er F1 Fantasy. Deltakere skal innen rimelig tid før deadline fastsatt av F1, registrere sitt eget lag til aktuell liga.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §1.3 Konkurransen
                    </h4>
                    <p className="paragraphAbout">
                        Regler for den innbyrdes konkurransen er fastsatt av F1, detaljert beskrivelse kan leses <a href="https://fantasy.formula1.com/game-rules">her</a>.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §1.4 Kåring av vinner
                    </h4>
                    <p className="paragraphAbout">
                        Da F1 ikke alltid er like raske til å summere endelige resultater for Fantasy, og vi er avhengig av å kåre en vinner av eventet i løpet av dagen, settes deadline for oppsummering av poeng i husets liga til 120 minutter etter målgang. Det vil si at eventuelle endringer i poenggivning etter dette tidspunkt ikke teller.
                    </p>
                </div>


                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §1.5 Tie-breaker
                    </h4>
                    <p className="paragraphAbout">
                        Dersom to eller flere deltakere står oppført med identisk poengsum iht
                        <strong> §1.3 </strong>, skal vinneren kåres gjennom F1 simulatoren.
                        Hver deltaker vil få ett forsøk hver a 3 runder, «flying-lap» rundt Monaco GP Circuit. Raskeste fører vil motta ett poeng til sitt Fantasy-lag.
                    </p>
                </div>
            </section>





            <section className="chapterOne">

                <div className="chapter">
                    <h2 className="chapterHead">
                        Husets regler
                    </h2>
                    <p className="chapterAbout">
                        Dette kapittelet er ett av kos og hygge. Her settes rammene for drikkeleken under sesongens mest innholdsrike og spennende F1-løp. Foruten Fantasy og trivelig lag, er det fint bli kvitt den kleine stillheten og ubehaget av å sitte så tett sammen foran televisjonen.
                    </p>
                    <br />
                </div>

                <h4 className="paragraph paragraphHead">§2.1 Pit-Stop</h4>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.1.1 Hjulskift
                    </h4>
                    <p className="paragraphAbout">
                        Ved hver Pit-stop for førere i deltakers lag, skal deltaker ta en slurk for hvert hjul skiftet.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.1.2 Langt Pit-stop
                    </h4>
                    <p className="paragraphAbout">
                        En slurk ved pitstop over 5 sekunder.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.1.3 Vingebytte
                    </h4>
                    <p className="paragraphAbout">
                        Ved vingebytte tar du en shot. Uklart hva shoten inneholder, kan variere fra år til år
                    </p>
                </div>


<br />

                <h4 className="paragraph paragraphHead">§2.2 Racing incidents</h4>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.2.1 Overtake
                    </h4>
                    <p className="paragraphAbout">
                        En slurk hver overtake, gjelder ikke i Pit
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.2.2 Overtake teamate
                    </h4>
                    <p className="paragraphAbout">
                        tre slurker.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.2.3 DNF
                    </h4>
                    <p className="paragraphAbout">
                        Chug et glass for egen fører, en slurk for øvrige.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.2.4 Safety Car (ikke virtuell)
                    </h4>
                    <p className="paragraphAbout">
                        Glasset skal fylles og fullføres før løpet fortsetter.
                    </p>
                </div>


                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.2.5 Blåflagg egen fører
                    </h4>
                    <p className="paragraphAbout">
                        En slurk.
                    </p>
                </div>

                <br />

                <h4 className="paragraph paragraphHead">§2.3 Radio</h4>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.3.1 Hamilton
                    </h4>
                    <p className="paragraphAbout">
                        En slurk ved klage over dekk-kvalitet.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.3.2 Verstappen/Perez
                    </h4>
                    <p className="paragraphAbout">
                        En slurk ved klage over strømmen i bilen.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.3.3 Horner
                    </h4>
                    <p className="paragraphAbout">
                        En slurk ved bruk av ordet mega.
                    </p>
                </div>

                <div className="paragraph">
                    <h4 className="paragraphHead">
                        §2.3.4 Radio Generell
                    </h4>
                    <p className="paragraphAbout">
                        En slurk ved bruk av "Keep pushing"
                    </p>
                </div>

            </section>

        </div>
    )
}
export default Rules;